
import { defineComponent, ref, watch, toRefs } from 'vue'

export default defineComponent({
    name: 'popupPicker',
    props: {
        isShowPicker: {
            type: Boolean,
        },
        columns: {
            type: Array,
            default: () => {
                return []
            },
        },
    },
    setup(props) {
        const { isShowPicker } = toRefs(props)
        const showPicker = ref(false)
        const getShowPicker = () => {
            showPicker.value = isShowPicker.value
        }
        watch(isShowPicker, getShowPicker)

        // let showPicker = ref(false)
        // watch(
        //   () => props.isShowPicker,
        //   () => {
        //     showPicker.value = props.isShowPicker
        //   }
        // )
        return {
            showPicker,
        }
    },
})
