import request from '../utils/request'
import qs from 'qs'
import { BASE_URL } from './config/index'
// import { myPageListParams } from '@/serviceType/myPages'

class MyPageService {
    // 我的列表
    docMySelectMyList(params: any) {
        return request({
            url: `${BASE_URL.MY}/selectMyList`,
            method: 'post',
            data: params,
        })
    }

    // 文件夹重命名
    docMyRenameFolder(params: any) {
        return request({
            url: `${BASE_URL.MY}/renameFolder`,
            method: 'post',
            data: params,
        })
    }

    // 文件夹删除
    docMyDeleteFolder(params: any) {
        return request({
            url: `${BASE_URL.MY}/deleteFolder`,
            method: 'post',
            data: params,
        })
    }

    // 我的文件夹
    docMySelectMyFolder(params: any) {
        const paramsStr = qs.stringify(params)
        return request({
            url: `${BASE_URL.MY}/getDocUserMy?${paramsStr}`,
            method: 'get',
            data: params,
        })
    }
       // 我的文件夹
       docTeamFolder(params: any) {
        const paramsStr = qs.stringify(params)
        return request({
            url: `${BASE_URL.MY}/getTeamFolder?${paramsStr}`,
            method: 'get',
            data: params,
        })
    }

    // 我的文件夹和团队文件夹
    docMySelectMyTeamFolder() {
        return request({
            url: `${BASE_URL.MY}/selectMyTeamFolder`,
            method: 'get',
        })
    }
}

export default new MyPageService()
