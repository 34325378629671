import {
  EDocType,
  wfioDfsUrl,
  wfioDocUrl,
  wfioUrl,
} from "@/serviceType/common";
import { IDocOpenParams } from "@/serviceType/myPage";
import { Base64 } from "js-base64";
import { useRouter, useRoute } from "vue-router";
import { ESessionKey } from "@/serviceType/session";

// 获取文件图标
export function chooseDocumentIcon(type: number) {
  switch (type) {
    case EDocType.Folder:
      return "folder_icon.png";
    case EDocType.Document:
      return "word_icon.png";
    case EDocType.Excel:
      return "excel_icon.png";
    case EDocType.PPT:
      return "ppt_icon.png";
    default:
      return "word_icon.png";
  }
}
// 获取缩略图，，PDF
// https://cloud.tencent.com/document/product/436/54058
export function previewUrl(docFullUrl: string, type = "jpg") {
  if (!docFullUrl) {
    return "";
  }
  return docFullUrl + "?ci-process=doc-preview&dstType=" + type;
}

export function randomString(len: number) {
  len = len || 32;
  const $chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  const maxPos = $chars.length;
  let pwd = "";
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

export function generateDocUrl(
  //   docKey: string,
  //   action: string,
  //   teamId?: string
  docParams: IDocOpenParams
) {
  console.log(docParams);
  const { docKey, action, docName, teamId, docSuffix } = docParams;
  const startRadom = randomString(8);
  const endRadom = randomString(8);
  /**
   * docSuffix 暂时用不到
   */
  // const queryStr = `action=${action}&docKey=${docKey}&teamId=${teamId}&docName=${docName}&docSuffix=${docSuffix}`;
  const queryStr = `action=${action}&docKey=${docKey}&teamId=${teamId}&docName=${docName}`;
  const queryStr1 = `${startRadom}_${Base64.encode(queryStr)}_${endRadom}`;
  const encodeQuery = encodeURIComponent(Base64.encode(queryStr1));

  const docUrl = `/document?${encodeQuery}`;
  return docUrl;
}

//时间格式转成毫秒数
export function dateToMillisecond(date: string) {
  let startDate = date;
  startDate = startDate.replace(new RegExp("-", "gm"), "/");
  const startDateM = new Date(startDate).getTime();
  return startDateM;
}

//升序
export function ascendingOrder(a: any, b: any) {
  return a.createDate - b.createDate;
}

//降序
export function descendingOrder(a: any, b: any) {
  return b.createDate - a.createDate;
}

// 获取文件后缀类型
const wordArr = ["doc", "dot", "wps", "wpt", "docx", "dotx", "docm", "dotm"];
const xlsxArr = ["xls", "xlt", "et", "xlsx", "xltx", "xlsm", "xltm"];
const pptArr = [
  "ppt",
  "pptx",
  "pptm",
  "ppsx",
  "ppsm",
  "pps",
  "potx",
  "potm",
  "dpt",
  "dps",
];
const pdfArr = ["pdf"];
export function getFileType(path: string) {
  const type = path?.split(".")?.pop()?.toLocaleLowerCase() || "";
  if (wordArr.includes(type)) {
    return "w";
  } else if (xlsxArr.includes(type)) {
    return "s";
  } else if (pptArr.includes(type)) {
    return "p";
  } else if (pdfArr.includes(type)) {
    return "f";
  }
  return "";
}

export function getFileSuffix(type: EDocType) {
  switch (type) {
    case EDocType.Document:
      return "docx";
    case EDocType.Excel:
      return "xlxs";
    case EDocType.PPT:
      return "pptx";
  }
}

export function stringToObject(str: string) {
  try {
    if (str && str.length > 0) {
      try {
        return JSON.parse(str);
      } catch (e) {
        return str;
      }
    } else {
      return null;
    }
  } catch (e: any) {
    console.log("err", e.message);
  }
}

export function getSession(key: ESessionKey) {
  const str = sessionStorage.getItem(key) as string;
  return stringToObject(str);
}

export function setSession(key: ESessionKey | string, value: string) {
  sessionStorage.setItem(key, value);
}

// 处理测试环境文件前缀地址
export function reverseDocFullUrl(url: string) {
  if (url && url?.includes(`${wfioDfsUrl}/cos/view`)) {
    const arr = url.split(`${wfioDfsUrl}/cos/view`);
    return `${wfioUrl}${arr[1]}`;
  }
  return url;
}
