
import { defineComponent, defineEmits } from "vue";

export default defineComponent({
    name: "ListVIew",
    props: {
        listData: {
            type: Array,
            default: () => {
                return [];
            },
        },
        fileType: {
            type: String,
            default: "",
        },
    },
    emits: ["docClick", "toOperate"],
    setup(props, context) {
        const { emit } = context;
        const handleDocClick = (item: any) => {
            emit("docClick", item);
        };
        const handleToOperate = (item: any, index: number) => {
            emit("toOperate", item, index);
        };
        return {
            handleDocClick,
            handleToOperate,
        };
    },
});
