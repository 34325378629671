import request from '../utils/request'
// import qs from 'qs'
import { BASE_URL } from './config/index'
import { teamfileListParams } from '@/serviceType/team'

class TeamService {
    // 我的团队列表
    docMyTeamList() {
        return request({
            url: `${BASE_URL.TEAM}/myTeamList`,
            method: 'post',
        })
    }

    // 新增团队
    addTeamName(params: any) {
        return request({
            url: `${BASE_URL.TEAM}/createTeam`,
            method: 'post',
            data: params,
        })
    }

    // 重命名团队
    modifyTeamName(params: any) {
        return request({
            url: `${BASE_URL.TEAM}/renameTeam`,
            method: 'post',
            data: params,
        })
    }

    // 置顶团队
    toTopTeam(params: any) {
        return request({
            url: `${BASE_URL.TEAM}/myTeamTop`,
            method: 'post',
            data: params,
        })
    }
    // 取消置顶
    removeTopTeam(params: any){
        return request({
            url: `${BASE_URL.TEAM}/myTeamRemoveTop`,
            method: 'post',
            data: params,
        })
    }
    
    // 删除团队
    deleteTeam(params: any) {
        return request({
            url: `${BASE_URL.TEAM}/deleteTeam`,
            method: 'post',
            data: params,
        })
    }

    // 团队文件列表
    teamfileList(params: teamfileListParams) {
        return request({
            url: `${BASE_URL.TEAM}/teamFileList`,
            method: 'post',
            data: params,
        })
    }

    // 修改文件夹名称
    renameTeamDir(params: any) {
        return request({
            url: `${BASE_URL.TEAM}/renameFolder`,
            method: 'post',
            data: params,
        })
    }

    // 文件夹删除
    deleteTeamFolder(params: any){
        return request({
            url: `${BASE_URL.TEAM}/deleteFolder`,
            method: 'post',
            data: params,
        })
    }

    // 修改文件权限
    modifyFolderPower(params: any){
        return request({
            url: `${BASE_URL.TEAM}/updateTeamFileAuth`,
            method: 'post',
            data: params,
        })
    }

    // 团员权限-管理员成员列表
    userMangeTeamAuthList(params: any) {
        return request({
            url: `${BASE_URL.TEAM}/membersAdminList?teamId=${params}`,
            method: 'get',
        })
    }

    // 团员权限-单个成员添加、修改、删除
    modifyTeamAuth(params: any) {
        return request({
            url: `${BASE_URL.TEAM}/saveTeamNumber`,
            method: 'post',
            data: params,
        })
    }

    // 团员权限-成员列表
    userTeamAuthList(params: any) {
        return request({
            url: `${BASE_URL.TEAM}/membersList`,
            method: 'post',
            data: params,
        })
    }

    // 团员转让
    transferTeam(params: any) {
        return request({
            url: `${BASE_URL.TEAM}/transferTeam`,
            method: 'post',
            data: params,
        })
    }

    // 团员权限-添加成员
    addTeamAuthList(params: any) {
        return request({
            url: `${BASE_URL.TEAM}/addMembers`,
            method: 'post',
            data: params,
        })
    }

    // 创建文件夹
    createTeamDir(params: any) {
        return request({
            url: `${BASE_URL.TEAM}/createDir`,
            method: 'post',
            data: params,
        })
    }

}
export default new TeamService()