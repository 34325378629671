export const enum ERoutName {
  my = "my",
  home = "home",
  team = "team",
  collection = "collection",
  recycleBin = "recycleBin",
  teamDetail = "teamDetail",
  power = "power",
  filePower = "filePower",
  document = "document",
  searchList = "searchList",
  moveList = "moveList",
  PREVIEW = "preview",
}
