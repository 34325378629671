import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!

  return (_openBlock(), _createBlock(_component_van_image, {
    src: _ctx.url||_ctx.defaultAvator,
    class: "img"
  }, {
    error: _withCtx(() => [
      _createVNode(_component_van_image, {
        class: "img",
        src: _ctx.defaultAvator
      }, null, 8, ["src"])
    ]),
    _: 1
  }, 8, ["src"]))
}