import request from '../utils/request'
// import qs from 'qs'
import { BASE_URL } from './config/index'

class HomeService {
  // 主页列表
  selectViewAndEditList(params: any) {
    return request({
      url: `${BASE_URL.HOME}/selectViewAndEditList`,
      method: 'post',
      data: params
    })
  }

  // 快速新建列表
  selectAddQuickNewList() {
    return request({
      url: `${BASE_URL.CREATEQUICK}/selectAddQuickNewList`,
      method: 'get',
    })
  }
  // 添加快速新建
  addQuickNew(params: any) {
    return request({
        url: `${BASE_URL.CREATEQUICK}/addQuickNew`,
        method: 'post',
        data: params,
    })
  }
  // 快速新建重命名
  renameCreateQuick(params: any) {
      return request({
          url: `${BASE_URL.CREATEQUICK}/renameCreateQuick`,
          method: 'post',
          data: params,
      })
  }
  // 主页-删除快速新建
  deleteCreateQuick(params: any) {
      return request({
          url: `${BASE_URL.CREATEQUICK}/deleteCreateQuick`,
          method: 'post',
          data: params,
      })
  }

  // 文档权限-成员列表
  docUserAuthSelectdocUserAuthList(params: any) {
    return request({
      url: `${BASE_URL.USERAUTH}/selectdocUserAuthList`,
      method: 'post',
      data: params,
    })
  }

  // 权限 添加成员
  docUserAuthAddDocUserAuth(params: any) {
    return request({
      url: `${BASE_URL.USERAUTH}/addDocUserAuth`,
      method: 'post',
      data: params,
    })
  }
}

export default new HomeService()