import request from '../utils/request'
// import qs from 'qs'
import { BASE_URL } from './config/index'

class RecycleBinService {
  // 回收站列表
  selectDeleteList(params: any) {
    return request({
      url:  `${BASE_URL.DELETE}/selectDeleteList`,
      method: 'post',
      data: params
    })
  }
  // 清空回收站
  clearAll() {
    return request({
      url:  `${BASE_URL.DELETE}/clearAll`,
      method: 'post',
    })
  }
  // 还原
  reduction(params: any) {
    return request({
      url:  `${BASE_URL.DELETE}/reduction`,
      method: 'post',
      data: params
    })
  }
  // 彻底删除
  thoroughDelete(params: any) {
    return request({
      url:  `${BASE_URL.DELETE}/thoroughDelete`,
      method: 'post',
      data: params
    })
  }
}

export default new RecycleBinService()