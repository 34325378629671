
import { defineComponent, ref, watch, toRefs } from 'vue'

export default defineComponent({
  name: 'avatorImg',
  props: {
    url: {
      type: String,
    },
  },
  setup() {
    const defaultAvator = ref('') 
    defaultAvator.value = require('@/assets/images/icons/default_avatar.png')
    return {
      defaultAvator
    }
  }
})
