import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import loginView from "../views/loginView.vue";
import { ERoutName } from "./type";
import errorView from "../views/errorView.vue";
import _t from "@/i18n";
// import homeView from "../views/homeManage/index.vue";
// import myView from "../views/myManage/index.vue";
// import teamView from "../views/teamManage/index.vue";
// import collectionView from "../views/collectionManage/index.vue";
// import recycleBinView from "../views/recycleBinManage/index.vue";
// import teamDetailView from "../views/teamManage/detail.vue";
// import teamPowerView from "../views/teamManage/power.vue";
// import documentView from "../views/documentManage/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: { path: "/home" },
  },
  {
    path: "/" + ERoutName.my,
    name: ERoutName.my,
    meta: { title: _t("doc.my") },
    component: () => import("../views/myManage/index.vue"),
  },
  {
    path: "/" + ERoutName.PREVIEW,
    name: ERoutName.PREVIEW,
    meta: { title: _t("doc.filePreview") },
    component: () => import("../views/preview.vue"),
  },
  {
    path: "/" + ERoutName.home,
    name: ERoutName.home,
    meta: { title: _t("doc.home") },
    component: () => import("../views/homeManage/index.vue"),
  },
  {
    path: "/" + ERoutName.team,
    name: ERoutName.team,
    meta: { title: _t("doc.team") },
    component: () => import("../views/teamManage/index.vue"),
  },
  {
    path: "/" + ERoutName.collection,
    name: ERoutName.collection,
    meta: { title: _t("doc.collect") },
    component: () => import("../views/collectionManage/index.vue"),
  },
  {
    path: "/" + ERoutName.recycleBin,
    name: ERoutName.recycleBin,
    meta: { title: _t("doc.recycleBin") },
    component: () => import("../views/recycleBinManage/index.vue"),
  },
  {
    path: "/" + ERoutName.teamDetail,
    name: ERoutName.teamDetail,
    meta: { title: _t("doc.teamDetail") },
    component: () => import("../views/teamManage/detail.vue"),
  },
  {
    //  团队权限管理
    path: "/" + ERoutName.power,
    name: ERoutName.power,
    meta: { title: _t("doc.authManage") },
    component: () => import("../views/teamManage/power.vue"),
  },
  {
    //  文件权限管理
    path: "/" + ERoutName.filePower,
    name: ERoutName.filePower,
    meta: { title: _t("doc.authManage") },
    component: () => import("../views/homeManage/power.vue"),
  },
  {
    path: "/" + ERoutName.document,
    name: ERoutName.document,
    meta: { title: _t("doc.document") },
    component: () => import("../views/documentManage/index.vue"),
  },
  // 搜索列表
  {
    path: "/" + ERoutName.searchList,
    name: ERoutName.searchList,
    meta: { title: _t("doc.search") },
    component: () => import("../views/searchManage/searchList.vue"),
  },
  // 移动列表
  {
    path: "/" + ERoutName.moveList,
    name: ERoutName.moveList,
    meta: { title: _t("doc.move") },
    component: () => import("../views/moveList/moveList.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: errorView,
  },
  //   {
  //       name:'login',
  //       path:'/login',
  //       component: loginView
  //   }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const auth = sessionStorage.getItem("Authorization");
  if (to.name !== "login" && !auth) {
    // window.location.href='https://m.henglink.com/'
    next();
  } else {
    next();
    window.LoganService.saveActionUrl();
  }
});
export default router;
