import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47826d4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup" }
const _hoisted_2 = { class: "ptitle" }
const _hoisted_3 = { class: "pmain" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: _ctx.showPicker,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showPicker) = $event)),
      closeable: "",
      round: "",
      position: "bottom",
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('operateFun', 'close')))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('doc.more')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_van_cell_group, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (item) => {
                return (_openBlock(), _createBlock(_component_van_cell, {
                  key: item,
                  title: item.text,
                  label: item.description,
                  onClick: ($event: any) => (_ctx.$emit('operateFun', item.type, item.isQuick, item))
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_van_image, {
                      src: require(`@/assets/icon/${item.type}.png`),
                      class: "typeicon"
                    }, null, 8, ["src"])
                  ]),
                  _: 2
                }, 1032, ["title", "label", "onClick"]))
              }), 128))
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}