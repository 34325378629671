import request from "../utils/request";
import qs from "qs";
import { BASE_URL } from "./config/index";

class EditorService {
  //恒力文档-跳转参数定义
  editorIndex(params: any) {
    const paramsStr = qs.stringify(params);
    return request({
      url: `${BASE_URL.EDITOR}/index?${paramsStr}`,
      method: "get",
      data: params,
    });
  }
  editorClose(params: any) {
    const paramsStr = qs.stringify(params);
    return request({
      url: `${BASE_URL.EDITOR}/close?${paramsStr}`,
      method: "post",
      data: params,
    });
  }

  getWpsUrl(params: any) {
    return request({
      url: `${BASE_URL.WPS}/link`,
      method: "post",
      data: params,
    });
  }

  // 预览附件
  previewAttachments(data: any) {
    return request({
      data,
      method: "post",
      url: `${BASE_URL.API_HOST}/api/common/link`,
    });
  }

  // 解密下载
  urlToDecrypt(data: any) {
    return request({
      data,
      method: "post",
      url: `${BASE_URL.DFS}/file/urlToDecrypt`,
    });
  }
}

export default new EditorService();
