import request from '../utils/request';
import qs from 'qs';
import { BASE_URL } from './config/index'


/*
 * @Author: 张绪
 * @Date: 2022-01-18 14:36:18
 * @LastEditors: zhangxu
 * @LastEditTime: 2022-01-18 15:01:27
 * @Description: 用户
 */
interface loginParams {
  username: string;
  password: string;
}
class AuthService {
  toLogin(params: loginParams) {
    return request({
      url: '/api/auth/oauth/token',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization:
          'Basic M2VjZjUyYmYyM2ZmNGFiOWI4MzkwN2I0OTY4ZjFmYjM6OTAxZjMyMzJlZDFlNGIyMTlkMTg1Y2ZkZWQ4ZGU0NzA=',
      },
      data: qs.stringify({
        grant_type: 'password',
        username: params.username,
        password: params.password,
        scope: 'app',
      }),
    });
  }

  // 获取登录信息
  authCheckToken(token: string) {
    const Base64 = require('js-base64').Base64;
    return request({
      url: `${BASE_URL.AUTH}/checkDocToken?token=${token}`,
      method: 'get',
      headers: {
        Authorization: `Basic ${Base64.encode(
          '8d33a5636f854c59a97b1d3f72c170b6:f775f16fbde645f6b735b9a36032c037'
        )}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }
}

export default new AuthService();
