import AuthService from './auth'
import RecycleBinService from './recycleBin'
import CollectionService from './collection'
import HomeService from './home'
import CommonService from './common'
import TeamService from './team'
import MyPageService from './myPage'
import EditorService from './editor'
export default {
  AuthService,
  RecycleBinService,
  CollectionService,
  HomeService,
  CommonService,
  TeamService,
  MyPageService,
  EditorService,
}
