import "babel-polyfill";
import Es6Promise from "es6-promise";
Es6Promise.polyfill();

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import service from "./service";
import jsBridge from "./utils/jsBridge";
import i18n, { lang } from "./i18n";
import "./assets/style/main.less";
import VConsole from "vconsole";
import "vant/lib/index.css";
import { Locale } from "vant";

import enUS from "vant/es/locale/lang/en-US";
import zhCN from "vant/es/locale/lang/zh-CN";
import { LoganService } from "@henglink/plugins";

window.LoganService = new LoganService({
  environment: process.env.VUE_APP_MODE || "",
  webSource: __PLATFORM__,
  version: __VERSION__,
});

if (process.env.VUE_APP_MODE !== "production") {
  new VConsole();
}
const app = createApp(App);

const isEnglish = lang === "en";
const locale = isEnglish ? enUS : zhCN;
const language = isEnglish ? "en-US" : "zh-CN";
Locale.use(language, locale);

// 全局注册组件
const requireComponent = require.context("./components", true, /\.vue$/);
requireComponent.keys().forEach((filePath: any) => {
  console.log(filePath);
  const componentName = filePath.match(/.\/(\S*).vue/)[1];
  app.component(componentName, requireComponent(filePath).default);
});

app.config.globalProperties.$service = service;
app.config.globalProperties.$jsBridge = jsBridge;
app.config.globalProperties.$t = i18n;
app.config.globalProperties.$store = store;
import { vantPlugins } from "./plugins/vant";
app.use(store).use(router).use(vantPlugins).mount("#app");
