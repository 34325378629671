import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!

  return (_openBlock(), _createBlock(_component_van_nav_bar, {
    title: _ctx.navTitle,
    "left-arrow": "",
    onClickLeft: _ctx.onClickLeft,
    class: "document-navbar"
  }, {
    right: _withCtx(() => [
      (_ctx.ishow)
        ? (_openBlock(), _createBlock(_component_van_icon, {
            key: 0,
            name: "search",
            onClick: _ctx.search,
            size: "18"
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "onClickLeft"]))
}