
import { defineComponent, reactive, ref, toRefs, Fragment, watch } from 'vue';
import httpService from '@/service/index';
import { getSession, setSession } from './utils/util'
import qs from 'qs';
import { isHenglinkApp } from './utils/common';
import jsBridge from './utils/jsBridge';
import { EApolloConfigKey } from './serviceType/common';
import { ESessionKey } from './serviceType/session'
export default defineComponent({
  setup() {
    const apiUrl = ref(
      `${process.env.VUE_APP_DOCUMENT_API}/web-apps/apps/api/documents/api.js` // to do 地址根据环境变化
    );
    return {
      apiUrl,
    };
  },
  created() {
    let token, userId

    const searchParams = new URL(location.href).searchParams
    if (searchParams) {
      //url中有参数先用url里的参数
      token = searchParams.get(ESessionKey.TOKEN)
      if (token) {
        setSession(ESessionKey.TOKEN, token)
      }

      userId = searchParams.get(ESessionKey.USER_ID)
      if (userId) {
        setSession(ESessionKey.USER_ID, userId)
      }
    }
    if (!token) {
      token = getSession(ESessionKey.TOKEN)
    }

    if (token) {
      setSession(ESessionKey.AUTHORIZATION, `bearer ${token}`)
      // 获取用户信息
      this.checkToken(token);
    }

    // ios刘海屏电池栏颜色
    if (isHenglinkApp) {
      jsBridge.statusBarColor({ barColor: '#ffffff', isShowBar: true });
    }
  },

  methods: {
    async checkToken(token: string) {
      try {
        const { code, data, msg }: any = await httpService.AuthService.authCheckToken(token);
        if (code === 0) {
          setSession(ESessionKey.AUTHORITY, data.authority)
          setSession(ESessionKey.USER_ID, data.user.userId)
          setSession(ESessionKey.USER_INFO, JSON.stringify(data.user))
          this.$store.dispatch('getConfigs', EApolloConfigKey.LOGAN_CONFIG)
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
});
