import request from '../utils/request'
// import qs from 'qs'
import { BASE_URL } from './config/index'

class CollectionService {
  // 收藏列表
  selectCollectionList(params: any) {
    return request({
      url: `${BASE_URL.COLLECTION}/selectCollectionList`,
      method: 'post',
      data: params
    })
  }
  // 收藏
  collection(params: any) {
    return request({
      url: `${BASE_URL.COLLECTION}/collection`,
      method: 'post',
      data: params
    })
  }
  // 删除收藏
  deleteCollection(params: any) {
    return request({
      url: `${BASE_URL.COLLECTION}/deleteCollection`,
      method: 'post',
      data: params
    })
  }
}

export default new CollectionService()