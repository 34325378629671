import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4211d24c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup" }
const _hoisted_2 = { class: "popup-title" }
const _hoisted_3 = { class: "create-box" }
const _hoisted_4 = { class: "span" }
const _hoisted_5 = {
  key: 0,
  class: "fast-part"
}
const _hoisted_6 = { class: "fast-title" }
const _hoisted_7 = { class: "mlist" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "fast-box" }
const _hoisted_10 = { class: "fast-name" }
const _hoisted_11 = { class: "ellipsis fsat-ellipsis" }
const _hoisted_12 = { class: "popup2" }
const _hoisted_13 = { class: "add-title" }
const _hoisted_14 = { class: "add-buttons" }
const _hoisted_15 = { class: "popup2" }
const _hoisted_16 = { class: "add-title" }
const _hoisted_17 = { class: "add-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_van_image = _resolveComponent("van-image")!
  const _component_van_popup = _resolveComponent("van-popup")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_button = _resolveComponent("van-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_icon, {
      name: "add",
      color: "#006fff",
      size: "40",
      class: "add-icon",
      onClick: _ctx.toAdd
    }, null, 8, ["onClick"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_van_popup, {
        show: _ctx.showAdd,
        "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showAdd) = $event)),
        closeable: "",
        round: "",
        position: "bottom"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('doc.add')), 1),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeList, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "type-list",
                key: item.value
              }, [
                _createVNode(_component_van_image, {
                  src: require(`@/assets/icon/${item.icon}.png`),
                  class: "type-icon",
                  onClick: ($event: any) => (_ctx.addNew(item.value, item.label))
                }, null, 8, ["src", "onClick"]),
                _createElementVNode("span", _hoisted_4, _toDisplayString(item.label), 1)
              ]))
            }), 128))
          ]),
          (_ctx.source == 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('doc.quickAdd')), 1),
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quickAddList, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "fast-list",
                      key: item.docKey,
                      onClick: ($event: any) => (_ctx.quickJump(item))
                    }, [
                      _createVNode(_component_van_image, {
                        src: require('@/assets/icon/word_icon.png'),
                        class: "img-preview"
                      }, null, 8, ["src"]),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_van_image, {
                            src: require(`@/assets/icon/${item.fileIcon}`),
                            class: "img-type"
                          }, null, 8, ["src"]),
                          _createElementVNode("span", _hoisted_11, _toDisplayString(item.docName), 1)
                        ]),
                        _createVNode(_component_van_icon, {
                          name: "ellipsis",
                          onClick: (e)=> _ctx.quickOperate(e, item)
                        }, null, 8, ["onClick"])
                      ])
                    ], 8, _hoisted_8))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["show"])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_van_popup, {
        show: _ctx.showCreateFolder,
        "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showCreateFolder) = $event)),
        style: { width: '80%' },
        round: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('doc.addFolder')), 1),
          _createVNode(_component_van_field, {
            modelValue: _ctx.folderName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.folderName) = $event)),
            label: _ctx.$t('doc.folderName'),
            placeholder: _ctx.$t('doc.pleaseEnterFolderName'),
            maxlength: "30"
          }, null, 8, ["modelValue", "label", "placeholder"]),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_van_button, {
              onClick: _ctx.cancelFolder,
              class: "btn"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('doc.cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_van_button, {
              onClick: _ctx.addFolder,
              style: {"color":"#006FFF"},
              class: "btn",
              loading: _ctx.btnLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('doc.confirm')), 1)
              ]),
              _: 1
            }, 8, ["onClick", "loading"])
          ])
        ]),
        _: 1
      }, 8, ["show"])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createVNode(_component_van_popup, {
        show: _ctx.showCreateFile,
        "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showCreateFile) = $event)),
        style: { width: '80%' },
        round: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('doc.addFile')), 1),
          _createVNode(_component_van_field, {
            modelValue: _ctx.fileName,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.fileName) = $event)),
            label: _ctx.$t('doc.fileName'),
            placeholder: _ctx.$t('doc.pleaseEnterFolderName'),
            maxlength: "30"
          }, null, 8, ["modelValue", "label", "placeholder"]),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_van_button, {
              onClick: _ctx.cancelFile,
              class: "btn"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('doc.cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_van_button, {
              onClick: _ctx.addfile,
              style: {"color":"#006FFF"},
              class: "btn",
              loading: _ctx.btnLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('doc.confirm')), 1)
              ]),
              _: 1
            }, 8, ["onClick", "loading"])
          ])
        ]),
        _: 1
      }, 8, ["show"])
    ])
  ], 64))
}