export default {
  doc: {
    loading: "Loading...",
    flash: "Try refreshing",
    backHome: "Back to Home",
    userName: "Please enter your username",
    password: "Please enter your password",
    loginBtn: "Login",
    cancel: "Cancel",
    confirm: "OK",
    search: "Search",
    move: "Moving",
    add: "New",
    edit: "Edit",
    view: "View only",
    remove: "Remove",
    delete: "Delete",
    back: "Back",
    submit: "Submit",
    createTime: "Creation time",
    editTime: "Modify Time",
    tip: "Tip",
    quickAdd: "Quick new",
    addQuickNew: "Add Quick new",
    addQuickNewSuccess: "Add Quick new successful!",
    folder: "Folders",
    file: "Documents",
    addFolder: "New Folder",
    folderName: "Folder Name",
    pleaseEnterFolderName: "Please enter a folder name",
    addFile: "Create a new file",
    fileName: "The file name",
    pleaseEnterFileName: "Please enter the file name",
    pleaseWriteFileName: "Please fill in the file name",
    openDoc: "Open a document",
    operateSuccess: "Operation successful",
    create: "Create",
    createSuccess: "Created successfully",
    createReplica: "Create Copy",
    replicaCreateSuccess: "Copy to create success!",
    replica: "copy",
    resource: "Source",
    more: "more",
    previewFail: "Preview failed",
    rename: "Rename",
    renameSuccess: "Renamed successfully!",
    quickAddRenameSuccess: "Successfully created and renamed quickly!",
    hengliDoc: "Hengli Documents",
    filePreview: "Preview",
    my: "My",
    home: "Home Page",
    collect: "collect",
    recycleBin: "Recycle Bin",
    authManage: "Permission Management",
    document: "Document",
    excel: "Forms",
    ppt: "Presentations",
    format: "Format",
    noMore: "No more",
    noData: "No data available",
    sendTo: "Send to",
    sendToChat: "Send to Chat",
    downloadFile: "Download file",
    downloadSourceFile: "Download the source files",
    download: "Download",
    hasDelUnableToDow: "The file has been deleted and cannot be downloaded",
    collectTime: "Collection time",
    cancelCollect: "Cancel collections",
    cancelCollectSuccess: "Cancel collection successfully!",
    collectSuccess: "Collection successful!",
    auth: "Permissions",
    recentOpen: "Recently Opened",
    recentEdit: "Recently edited",
    templateCreate: "Template creation",
    addMember: "Add Member",
    addMost: "Add up to {} people",
    existing: "existing",
    canAdd: "You can also add new",
    creator: "creator",
    manager: "Manager",
    member: "Member",
    person: "{} people",
    canEdit: "Editable",
    canEditDesc: "Can be viewed/edited/downloaded",
    canView: "Viewable",
    canViewDesc: "Can be viewed/downloaded",
    currentNoFolder: "There are currently no folders",
    allCanEdit: "Everyone can edit",
    creatorCanEdit: "File creators, team administrators, and creators can edit",
    moveToHere: "Move here",
    myFile: "My Files",
    deleteTip: "Delete Tips",
    deleteContentTip:
      "Are you sure you want to delete it? After deletion, it can be retrieved in the recycle bin.",
    deleteSuccess: "Successfully deleted!",
    cancelDelete: "Cancel deletion",
    removeSuccess: "Successfully removed!",
    clearRecycleBin: "Empty Recycle Bin",
    clearRecycleBinTip: "Are you sure you want to empty the recycle bin?",
    clearSuccess: "Clear successfully!",
    all: "All",
    revert: "Restore",
    revertSuccess: "Restore successful!",
    thoroughDelete: "Delete All",
    thoroughDeleteTip:
      "Are you sure you want to completely delete it? After deletion, it cannot be retrieved.",
    position: "Location",
    pleaseEnterKeywordSearch: "Please enter search keywords",
    setSuccess: "Set successfully!",
    deleteFolderTip: "Are you sure to delete the {} folder?",
    deleteFileTip: "Are you sure you want to delete the {} file?",
    top: "Top",
    cancelTop: "Cancel Top",
    topSuccess: "Top success!",
    cancelTopSuccess: "Cancel top successful!",
    team: "team",
    teamName: "Team Name",
    pleaseEnterTeamName: "Please fill in the team name",
    teamDetail: "Team Details",
    myTeam: "My team",
    addTeam: "New Team",
    dissolveTeam: "Dissolve the team",
    dissolveTeamTip:
      'Are you sure to dissolve the 【{}】 team? After disbanding the team, the files in the team are automatically archived to the file creator\'s "My/{}/" directory. After dissolution, the team cannot be restored.',
    transferTeam: "Transfer team",
    teamTransfer: "Team Transfer",
    transferMember: "Become a member",
    transferManager: "Transfer to Manager",
    noManagerTransferTip: "The team has no administrators to transfer!",
    transferSuccess: "Transfer successful!",
    saveToCloud: 'Save to cloud',
  },
};
