
import { defineComponent, ref, watch, toRefs, getCurrentInstance } from 'vue'
import { Toast, Notify } from 'vant'
import service from '@/service'
export default defineComponent({
    name: 'createCopy',
    props: {
        isShowDialog: {
            type: Boolean,
        },
        //目标对象类型  1.我的，2.团队
        destObjectType: {
            type: Number,
        },
        //目标团队id  团队传teamId 其他传null
        destTeamId: {
            type: String,
        },
        //文件唯一值
        docKey: {
            type: String,
        },
        //创建副本新的文件名
        docName: {
            type: String,
        },
        //团队传parentId 我的传1
        parentId: {
            type: String,
        },
        //来源 1:主页或收藏 2:我的 3:团队
        source: {
            type: Number,
        }
    },
    setup(props) {
        const { proxy }: any = getCurrentInstance();
        const { isShowDialog, destObjectType, destTeamId, docKey, docName, parentId, source } = toRefs(props)

        const showDialog = ref(false)
        const parms: any = ref({})
        const temp = ref(false)

        const getShowPicker = () => {
            showDialog.value = isShowDialog.value
            parms.value.destObjectType = destObjectType.value
            parms.value.destTeamId = destTeamId.value
            parms.value.docKey = docKey.value
            parms.value.docName = docName.value + proxy.$t('doc.replica')
            parms.value.parentId = parentId.value
            parms.value.source = source.value
            if (isShowDialog.value) {
                temp.value = false
            }
        }

        watch(isShowDialog, getShowPicker)
        return {
            showDialog,
            parms,
            temp,
            proxy
        }
    },
    methods: {
        submitTeam() {
            this.temp = true
            if (this.parms.docName !== '') {
                service.CommonService.createCopy(this.parms).then((res: any) => {
                    if (res.code === 0) {
                        Toast({ position: 'bottom', message: this.proxy.$t('doc.replicaCreateSuccess') })
                        this.$emit('createFun', true)
                    } else {
                        Toast.fail(res.msg)
                        // this.$emit('createFun', false)
                        this.temp = false
                    }
                })
            } else {
                this.temp = false
            }

        },
        cancel() {
            this.$emit('createFun', false)
        }
    }
})
