import { App as VM } from 'vue';
import {
  Button,
  List,
  Cell,
  CellGroup,
  Tabbar,
  TabbarItem,
  Field,
  Grid,
  GridItem,
  Image,
  Icon,
  NavBar,
  Tab,
  Tabs,
  PullRefresh,
  Popup,
  Picker,
  Dialog,
  Toast,
  Popover,
  RadioGroup,
  Radio,
  Empty,
  Form
} from 'vant';

const plugins = [
  Button,
  List,
  Cell,
  CellGroup,
  Tabbar,
  TabbarItem,
  Field,
  Grid,
  GridItem,
  Image,
  Icon,
  NavBar,
  Tab,
  Tabs,
  PullRefresh ,
  Popup,
  Picker,
  Dialog,
  Toast,
  Popover,
  RadioGroup,
  Radio,
  Empty,
  Form
];

export const vantPlugins = {
  install: function (vm: VM) {
    plugins.forEach((item) => {
      vm.component(item.name, item);
    });
  },
};
