export default {
  doc: {
    loading: "加载中...",
    flash: "刷新试试",
    backHome: "返回首页",
    userName: "请输入用户名",
    password: "请输入密码",
    loginBtn: "登陆",
    cancel: "取消",
    confirm: "确定",
    search: "搜索",
    move: "移动",
    add: "新建",
    edit: "编辑",
    view: "查看",
    remove: "移除",
    delete: "删除",
    back: "返回",
    submit: "提交",
    createTime: "创建时间",
    editTime: "修改时间",
    tip: "提示",
    quickAdd: "快速新建",
    addQuickNew: "添加快速新建",
    addQuickNewSuccess: "添加快速新建成功！",
    folder: "文件夹",
    file: "文件",
    addFolder: "新建文件夹",
    folderName: "文件夹名称",
    pleaseEnterFolderName: "请输入文件夹名称",
    addFile: "新建文件",
    fileName: "文件名称",
    pleaseEnterFileName: "请输入文件名称",
    pleaseWriteFileName: "请填写文件名称",
    openDoc: "打开文档",
    operateSuccess: "操作成功",
    create: "创建",
    createSuccess: "创建成功",
    createReplica: "创建副本",
    replicaCreateSuccess: "副本创建成功！",
    replica: "副本",
    resource: "来源",
    more: "更多",
    previewFail: "预览失败",
    rename: "重命名",
    renameSuccess: "重命名成功！",
    quickAddRenameSuccess: "快速新建重命名成功！",
    hengliDoc: "恒力文档",
    filePreview: "文件预览",
    my: "我的",
    home: "主页",
    collect: "收藏",
    recycleBin: "回收站",
    authManage: "权限管理",
    document: "文档",
    excel: "表格",
    ppt: "演示文档",
    format: "格式",
    noMore: "没有更多了",
    noData: "暂无数据",
    sendTo: "发送到",
    sendToChat: "发送到聊天",
    downloadFile: "下载文件",
    downloadSourceFile: "下载源文件",
    download: "下载",
    hasDelUnableToDow: "该文件已被删除，不能下载",
    collectTime: "收藏时间",
    cancelCollect: "取消收藏",
    cancelCollectSuccess: "取消收藏成功！",
    collectSuccess: "收藏成功！",
    auth: "权限",
    recentOpen: "最近打开",
    recentEdit: "最近编辑",
    templateCreate: "模板创建",
    addMember: "添加成员",
    addMost: "最多添加{}人",
    existing: "现有",
    canAdd: "还可以新增",
    creator: "创建者",
    manager: "管理者",
    member: "成员",
    person: "{}人",
    canEdit: "可编辑",
    canEditDesc: "可查看/编辑/下载",
    canView: "可查看",
    canViewDesc: "可查看/下载",
    currentNoFolder: "当前没有文件夹",
    allCanEdit: "所有人可以编辑",
    creatorCanEdit: "文件创建者、团队管理员和创建者可以编辑",
    moveToHere: "移动到此处",
    myFile: "我的文件",
    deleteTip: "删除提示",
    deleteContentTip: "确定要删除吗？删除后可以在回收站找回。",
    deleteSuccess: "删除成功！",
    cancelDelete: "取消删除",
    removeSuccess: "移除成功！",
    clearRecycleBin: "清空回收站",
    clearRecycleBinTip: "确定要清空回收站吗？",
    clearSuccess: "清空成功！",
    all: "全部",
    revert: "还原",
    revertSuccess: "还原成功！",
    thoroughDelete: "彻底删除",
    thoroughDeleteTip: "确定要彻底删除吗？删除后就不可以找回了。",
    position: "位置",
    pleaseEnterKeywordSearch: "请输入搜索关键词",
    setSuccess: "设置成功！",
    deleteFolderTip: "确定删除 {} 文件夹？",
    deleteFileTip: "确定要删除 {} 文件吗？",
    top: "置顶",
    cancelTop: "取消置顶",
    topSuccess: "置顶成功！",
    cancelTopSuccess: "取消置顶成功！",
    team: "团队",
    teamName: "团队名称",
    pleaseEnterTeamName: "请填写团队名称",
    teamDetail: "团队详情",
    myTeam: "我的团队",
    addTeam: "新建团队",
    dissolveTeam: "解散团队",
    dissolveTeamTip:
      "确定解散 【{}】 团队？解散团队后，团队中的文件自动归档到文件创建者“我的/{}/”目录下。解散后无法恢复团队。",
    transferTeam: "转让团队",
    teamTransfer: "团队转让",
    transferMember: "转成成员",
    transferManager: "转成管理者",
    noManagerTransferTip: "团队没有管理员可转让！",
    transferSuccess: "转让成功！",
    saveToCloud: '存至云盘',
  },
};
