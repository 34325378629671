import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cdf0b2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fail-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!

  return (_openBlock(), _createBlock(_component_van_image, { src: _ctx.src }, {
    error: _withCtx(() => [
      _createVNode(_component_van_image, {
        src: _ctx.fail,
        class: "fail-img"
      }, null, 8, ["src"]),
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('doc.previewFail')), 1)
    ]),
    _: 1
  }, 8, ["src"]))
}