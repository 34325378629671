import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-390f1d86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup" }
const _hoisted_2 = { class: "popupTitle" }
const _hoisted_3 = { class: "submitBtn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_form = _resolveComponent("van-form")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: _ctx.showDialog,
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDialog) = $event)),
      onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('createFun', false))),
      style: { width: '80%' },
      round: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('doc.addQuickNew')), 1),
        _createVNode(_component_van_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_van_field, {
              modelValue: _ctx.parms.docName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.parms.docName) = $event)),
              name: _ctx.$t('doc.fileName'),
              label: _ctx.$t('doc.fileName'),
              placeholder: _ctx.$t('doc.fileName'),
              rules: [{ required: true, message: _ctx.$t('doc.pleaseWriteFileName') }]
            }, null, 8, ["modelValue", "name", "label", "placeholder", "rules"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_van_button, { onClick: _ctx.cancel }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('doc.cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_van_button, {
                style: {"color":"#006fff"},
                loading: _ctx.temp,
                onClick: _ctx.submitTeam
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('doc.confirm')), 1)
                ]),
                _: 1
              }, 8, ["loading", "onClick"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}