
import { defineComponent, ref, watch, toRefs, getCurrentInstance } from 'vue';
import { Toast, Notify } from 'vant';
import service from '@/service';
export default defineComponent({
    name: 'addQuickNew',
    props: {
        isShowDialog: {
            type: Boolean,
        },
        //文件唯一值
        // docKey: {
        //   type: String,
        // },
        // //创建副本新的文件名
        // docName: {
        //   type: String,
        // },
        //来源 1:主页或收藏 2:我的 3:团队
        source: {
            type: Number,
        },
        detail: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    setup(props) {
        const { proxy }: any = getCurrentInstance();
        const { isShowDialog, source, detail } = toRefs(props);
        const showDialog = ref(false);
        const parms: any = ref({});
        const temp = ref(false);

        const getShowPicker = () => {
            showDialog.value = isShowDialog.value;
            parms.value.docKey = detail.value.docKey;
            parms.value.docName = detail.value.docName;
            parms.value.teamId = detail.value.teamId
            parms.value.source = source.value;
            if (isShowDialog.value) {
                temp.value = false;
            }
        };

        watch(isShowDialog, getShowPicker);
        return {
            showDialog,
            parms,
            temp,
            proxy
        };
    },
    methods: {
        submitTeam() {
            this.temp = true;
            if (this.parms.docName !== '') {
                service.CommonService.addQuickNew(this.parms).then((res: any) => {
                    if (res.code === 0) {
                        Notify({ type: 'success', message: this.proxy.$t('doc.addQuickNewSuccess') });
                        this.$emit('createFun', true);
                    } else {
                        Toast.fail(res.msg);
                        this.temp = false;
                    }
                });
            } else {
                this.temp = false;
            }
        },
        cancel() {
            this.$emit('createFun', false);
        },
    },
});
