import {
  EApolloConfigKey,
  EMutations,
  ILoganConfig,
} from "./../serviceType/common";
import { createStore, Commit, Dispatch } from "vuex";
import CommonService from "@/service/common";
interface IAction {
  commit: Commit;
  dispatch: Dispatch;
  state: IStateProps;
}

interface IStateProps {
  apolloConfigData: any;
}

const defaultState = {
  apolloConfigData: {},
};

export default createStore({
  state: { ...defaultState },
  getters: {},
  mutations: {
    setApolloConfigData(state, data) {
      state.apolloConfigData = data;
    },
  },
  actions: {
    /**
     * @description 获取统一配置,生成map对象  老的统一配置，废弃不用，新配置走apollo
     * @returns
     */
    async getConfigs({ commit, state }: IAction, configKey: EApolloConfigKey) {
      if (state.apolloConfigData[configKey]) {
        return state.apolloConfigData[configKey];
      }

      const configKeyArr = Object.values(EApolloConfigKey).map(
        (item: EApolloConfigKey) => {
          return item;
        }
      );
      const { data } = await CommonService.getConfigs(configKeyArr);
      if (data) {
        //接口返回的是map对象，直接用,自己根据配置的内容（可能配置的是字符串，也可能配置是JSON字符串），再决定是否JSON.parse()，
        commit(EMutations.SET_APOLLO_CONFIG_DATA, data);
        switch (configKey) {
          case EApolloConfigKey.LOGAN_CONFIG: {
            //拿到logan配置后需要执行logan的定时器
            data as ILoganConfig;
            window.LoganService.startInterval(data[configKey].interval);
            break;
          }

          default:
            break;
        }
      }
      return state.apolloConfigData[configKey];
    },
  },
  modules: {},
});
