
import { defineComponent, ref, watch, toRefs, reactive, getCurrentInstance } from 'vue';
import { AddTypeList, EDocType, ELocationSource, IDocType, EAction } from '@/serviceType/common';
import { IDocOpenParams } from "@/serviceType/myPage";
import { Toast } from 'vant';
import { generateDocUrl, getFileSuffix } from '@/utils/util';
import service from '@/service';

export default defineComponent({
    name: 'addDocument',
    props: {
        source: {
            type: Number,
        },
        quickAddList: {
            type: Array,
        },
        teamId: {
            type: Number,
        },
        parentId: {
            type: String,
        },
    },
    setup(props) {
        const { proxy }: any = getCurrentInstance();
        const { source } = toRefs(props);
        const btnLoading = ref(false);
        const folderName = ref('');
        const fileName = ref(proxy.$t('doc.add'))
        let showAdd = ref(false);
        let showCreateFolder = ref(false);
        let showCreateFile = ref(false)
        let filetype = ref()
        let list = reactive({
            typeList: AddTypeList,
        });

        const toAdd = () => {
            showAdd.value = true;
        };

        let folderIdx = list.typeList.findIndex((item: IDocType) => item.value === EDocType.Folder);
        if (source.value === ELocationSource.home) {
            if (folderIdx != -1) {
                list.typeList.splice(folderIdx, 1);
            }
        } else {
            if (folderIdx === -1) {
                list.typeList.push({ label: proxy.$t('doc.folder'), icon: 'folder_icon', value: EDocType.Folder });
            }
        }

        return {
            proxy,
            showAdd,
            toAdd,
            showCreateFolder,
            folderName,
            filetype,
            btnLoading,
            showCreateFile,
            fileName,
            ...toRefs(list),
        };
    },
    emits: ['addFun', 'addFolder', 'quickOperate'],
    methods: {
        addNew(type: any, label: string) {
            window.LoganService.saveAction(this.proxy.$t('doc.add') + label)
            this.filetype = type
            if (type === EDocType.Folder) {
                this.showAdd = false;
                this.showCreateFolder = true;
                this.folderName = ''
                return;
            } else {
                this.showAdd = false;
                this.showCreateFile = true;

            }

        },

        //取消新建文件夹
        cancelFolder() {
            this.showCreateFolder = false;
        },
        // 取消新建文件
        cancelFile() {
            this.showCreateFile = false
        },
        addfile() {
            service.CommonService.newDocument({
                docName: this.fileName,
                newDocType: this.filetype,
                parentId: this.parentId,
                source: this.source,
                teamId: this.teamId,
            }).then((res: any) => {
                if (res.code == 0) {
                    const docSuffix = getFileSuffix(this.filetype)
                    this.jumpToDocument({ docSuffix, docKey: res.data, action: EAction.EDIT, docName: '' });
                    this.$emit('addFun');
                } else {
                    Toast(res.msg);
                }
            });
        },
        // add folder接口
        async addFolder() {
            const parms = {
                folderName: this.folderName,
                newDocType: EDocType.Folder,
                parentId: this.parentId,
                source: this.source,
                teamId: this.teamId,
            };
            const teamParms = {
                parentId: this.parentId,
                teamId: this.teamId,
                folderName: this.folderName,
            };
            console.log(this.teamId);
            this.btnLoading = true;
            const { data, code, msg }: any = this.teamId ? await service.TeamService.createTeamDir(teamParms) : await service.CommonService.createDir(parms);

            if (code === 0) {
                Toast.success(this.proxy.$t('doc.operateSuccess'));
                this.showCreateFolder = false;
                this.$emit('addFolder');
            } else {
                Toast.fail(msg);
            }
            this.btnLoading = false;
        },

        // 打开文档
        jumpToDocument(params: IDocOpenParams) {
            const docUrl = generateDocUrl(params);
            this.$router.push(docUrl);
        },
        // 快速新建打开文档
        quickJump(item: any) {
            const docSuffix = getFileSuffix(item.docFileType)
            this.jumpToDocument({ docSuffix, docKey: item.docKey, action: EAction.EDIT, docName: item.docName });
        },

        quickOperate(e: Event,item: any) {
            e.stopPropagation()
            this.$emit('quickOperate', item)
        }
    },
});
