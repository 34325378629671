
import { defineComponent, ref, watch, toRefs } from 'vue';
export default defineComponent({
  name: 'scriptElement',
  props: {
    apiUrl: {
      type: String,
    },
  },
  setup(props) {
    const { apiUrl } = toRefs(props);
    const scriptEle: any = document.createElement('script');
    scriptEle.src = apiUrl.value;
    document.body.appendChild(scriptEle);
    return {};

  },
  methods: {},
});
