
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default defineComponent({
    name: 'previewImg',
    props: {
        src: {
            type: String,
        },
    },
    setup() {
        const fail = require('@/assets/icon/preview_new.png');
        return {
            fail,
        };
    },
    methods: {},
});
