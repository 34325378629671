/*
 * @Author: 张绪
 * @Date: 2022-01-18 14:36:18
 * @LastEditors: zhangxu
 * @LastEditTime: 2022-01-18 15:01:27
 * @Description: 原生交互
 */
function HLJsBridge(callback: any) {
  const ua = (navigator && navigator.userAgent) || '';
  if (ua.indexOf('HENGLINKNewApp') > -1) {
    const WKWebViewJavascriptBridge: any = (window as any).WKWebViewJavascriptBridge;
    const WebViewJavascriptBridge: any = (window as any).WebViewJavascriptBridge;
    if ((window as any).WKWebViewJavascriptBridge) {
      return callback(WKWebViewJavascriptBridge);
    } else if ((window as any).WKWVJBCallbacks) {
      return (window as any).WKWVJBCallbacks.push(callback);
    } else {
      if ((window as any).WebViewJavascriptBridge) {
        callback(WebViewJavascriptBridge);
      } else {
        document.addEventListener(
          'WebViewJavascriptBridgeReady',
          function () {
            callback(WebViewJavascriptBridge);
          },
          false
        );
      }
    }
    const ua = navigator.userAgent;
    //判断手机类型 安卓或IOS
    const mobileType = ua.indexOf('Android') > -1 || ua.indexOf('linux') > -1;
    if (!mobileType) {
      (window as any).WKWVJBCallbacks = [callback];
      (window as any).webkit?.messageHandlers?.iOS_Native_InjectJavascript?.postMessage(null);
    }
  }
}

try {
  HLJsBridge(function (bridge: any) {
    bridge?.callHandler('register', '', function (resp: any) {
      console.log(JSON.parse(resp));
    });
  });
} catch (err) {
  console.log(err);
}

class JSBridge {
  // h5调用原生方法
  // name 原生提供的方法名
  // data h5传递给原生的参数 json格式
  // callback 原生返回的回调
  callhandler(name: any, data: any, callback: any) {
    HLJsBridge(function (bridge: any) {
      console.log(name);
      bridge?.callHandler(name, data, callback);
    });
  }

  // 原生调用h5方法
  registerhandler(name: any, callback: any) {
    HLJsBridge(function (bridge: any) {
      bridge?.registerHandler(name, function (data: any, responseCallback: any) {
        callback(data, responseCallback);
      });
    });
  }

  // 获取登陆用户信息
  getLoginUser() {
    return new Promise((resolve, reject) => {
      try {
        this.callhandler('getLoginUser', '', function (resp: any) {
          if (resp) {
            const data = JSON.parse(resp);
            const userInfo = {
              Authorization: data.Authorization,
              ...data,
            };
            localStorage.setItem('Authorization', data.Authorization);
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            resolve(resp);
          } else {
            resolve(false);
          }
        });
      } catch (e) {
        reject(e);
      }
    });
  }
  // 退出登录
  logout() {
    return new Promise((resolve, reject) => {
      try {
        this.callhandler('logout', '', function (resp: any) {
          if (resp) {
            resolve(resp);
          } else {
            resolve(false);
          }
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  //发送到
  openRecentConversation(params: any) {
    console.log(params);
    return new Promise((resolve, reject) => {
      try {
        this.callhandler('openRecentConversation', params, (response: any) => {
          if (response) {
            resolve(response);
          } else {
            reject(false);
          }
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  // 文档权限管理
//   openDocUserAuth(params: any) {
//     console.log(params, 123);
//     return new Promise((resolve, reject) => {
//       try {
//         this.callhandler('openDocUserAuth', params, (response: any) => {
//           if (response) {
//             resolve(response);
//           } else {
//             reject(false);
//           }
//         });
//       } catch (e) {
//         reject(e);
//       }
//     });
//   }

  backToApp() {
    return new Promise((resolve, reject) => {
      try {
        this.callhandler('backToApp', '', (response: any) => {
          if (response) {
            resolve(response);
          } else {
            reject(false);
          }
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  //   设置权限
  openAddressBook(params: any, callback: any) {
    this.callhandler('openAddressBook', params, callback);
  }

  // 文档下载
  downloadDocument(params: any, callback: any) {
    this.callhandler('downloadDocument', params, callback);
  }

  statusBarColor(data:any){
    this.callhandler('statusBarColor', data, '')
  }
  // 保存至云盘
  jumpCloudDisk(data: any) {
    this.callhandler('jumpCloudDisk', data, '')
  }
  // 获取用户信息
  getUserInfo() {
    return new Promise((resolve, reject) => {
      try {
        this.callhandler("getUserInfo", "", (response: any) => {
          if (response) {
            resolve(JSON.parse(response));
          } else {
            resolve(null);
          }
        });
      } catch (e) {
        reject(e);
      }
    });
  }
}

export default new JSBridge();
