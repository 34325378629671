const perfixApi = "/api/document";
const api = "/api";
export const BASE_URL = {
  API_HOST: perfixApi,
  AUTH: `${perfixApi}/docAuth`,
  MY: `${perfixApi}/docMy`,
  EDITOR: `${perfixApi}/editor`,
  CREATEQUICK: `${perfixApi}/docCreateQuick`,
  COLLECTION: `${perfixApi}/docCollection`,
  USERAUTH: `${perfixApi}/docUserAuth`,
  DELETE: `${perfixApi}/docDelete`,
  TEAM: `${perfixApi}/docTeam`,
  COMMON: `${perfixApi}/docCommon`,
  HOME: `${perfixApi}/docMain`,
  WPS: `${perfixApi}/api`,
  COMPONENT: `${api}/component`,
  DFS: `${api}/dfs`,
  CLOUD_DISK: `${api}/clouddisk`,
};
