import axios from "axios";
const httpTimeout = 30000; //axios请求超时时间
import router from "../router";
import { isHenglinkApp } from "./common";
import JSBridge from "./jsBridge";
import { lang } from "@/i18n";
const instance = axios.create({
  timeout: httpTimeout,
});

export enum ELanguage {
  EN = "en",
  ZHCN = "zhCN",
  ZH_RCN = "zh_rCN",
}
const Language = lang === ELanguage.EN ? ELanguage.EN : ELanguage.ZH_RCN;

instance.interceptors.request.use(
  (config: any) => {
    const authority = sessionStorage.getItem("authority");
    const Authorization = sessionStorage.getItem("Authorization");
    const userId = sessionStorage.getItem("userId");
    config.headers.Language = Language;
    if (authority) {
      config.headers.authority = authority;
    }

    if (!config.headers.Authorization && Authorization) {
      config.headers.Authorization = Authorization;
    }

    if (!config.headers.userId && userId) {
      config.headers.userId = userId;
    }
    window.LoganService.saveActionRequest(config);
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (response: { data: any }) => {
    return response.data;
  },
  (error: any) => {
    if (error?.response?.status === 401) {
      if (!isHenglinkApp) {
        // window.location.href = "/login";
        window.location.href = 'https://m.henglink.com/'
      } else {
        // 此处调原生退出登陆
        JSBridge.logout();
      }
    } else {
      let status = "",
        message = error.message;
      if (error?.response) {
        status = error.response.status;
        message = error.response?.data?.msg || error.message || "";
      }
      router.push({
        path: "/error",
        query: { status, message, url: error.config.url },
      });
    }
    return Promise.reject(error);
  }
);

export default instance;
