import _t from "@/i18n";

// 文档类型 1:文档 2：表格 3：ppt 4 文件夹
export enum EDocType {
  Document = 1,
  Excel = 2,
  PPT = 3,
  Folder = 4,
}
//来源 1:主页 2:我的 3 : 团队
export enum ELocationSource {
  home = 1,
  my = 2,
  Team = 3,
}
// 默认顶部Id
export enum ETopId {
  Top = "1",
}

//发送操作类型，0：分享，1：发送副本，2：邀请协作，3：发送PDF
export enum ESendType {
  Share = 0,
  SendCopy = 1,
  Collaboration = 2,
  SendPDF = 3,
}

//消息类型，0：公有云（已停用）1：云商，2：私有化 默认
export enum EMessageType {
  Public = 0,
  CloudBusiness = 1,
  Private = 2,
}

export interface IDocType {
  label: string;
  icon: string;
  value: number;
}
// 新增文档类型 icon
export const AddTypeList: Array<IDocType> = [
  { label: _t("doc.document"), icon: "word_icon", value: EDocType.Document },
  { label: _t("doc.excel"), icon: "excel_icon", value: EDocType.Excel },
  { label: "PPT", icon: "ppt_icon", value: EDocType.PPT },
  { label: _t("doc.folder"), icon: "folder_icon", value: EDocType.Folder },
];
// 是否有权限
export enum EIsAuth {
  YES = 1,
  No = 2,
}

export enum EAction {
  ADD = "add",
  SEND = "send",
  VIEW = "view",
  EDIT = "edit",
  POWER = "power",
  ECTYPE = "ectype",
  RENAME = "rename",
  REMOVE = "remove",
  DELETE = "delete",
  COLLECT = "collect",
  DELCOLLECT = "delCollect",
  FAST_CREATE = "fast_create",
  DOWNLOAD_PDF = "download_pdf",
  DOWNLOAD_FILE = "download_file",
}

export enum EApolloConfigKey {
  LOGAN_CONFIG = "LOGAN_CONFIG",
}

export interface ILoganConfig {
  interval: number;
  webSource: Array<string>; // 所有前端项目git名配置
}

export enum EMutations { //store 的Mutations
  SET_APOLLO_CONFIG_DATA = "setApolloConfigData",
}

export const wfioUrl = process.env.VUE_APP_WFIO;
export const wfioDfsUrl = process.env.VUE_APP_WFIO_DFS;
export const wfioDocUrl = process.env.VUE_APP_WFIO_DOC_URL;
