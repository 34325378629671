import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-745f2017"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "t-name" }
const _hoisted_3 = { class: "name" }
const _hoisted_4 = { class: "line name-text" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_preview_img = _resolveComponent("preview-img")!
  const _component_van_image = _resolveComponent("van-image")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listData, (item) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "thumb-css",
      key: item.id
    }, [
      _createElementVNode("div", {
        class: "t-preview",
        onClick: ($event: any) => (_ctx.handleDocClick(item))
      }, [
        _createVNode(_component_preview_img, {
          src: item.previewUrl,
          class: "img-thumb"
        }, null, 8, ["src"])
      ], 8, _hoisted_1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createVNode(_component_van_image, {
              src: require(`@/assets/icon/${item.fileIcon}`),
              class: "img-type mr6"
            }, null, 8, ["src"])
          ]),
          _createElementVNode("span", _hoisted_4, _toDisplayString(item.docName || item.folder), 1)
        ]),
        _createElementVNode("div", {
          onClick: ($event: any) => (_ctx.handleToOperate(item, 1))
        }, [
          _createVNode(_component_van_image, {
            src: require('@/assets/icon/more_icon.png'),
            class: "img-type"
          }, null, 8, ["src"])
        ], 8, _hoisted_5)
      ])
    ]))
  }), 128))
}