import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c20f176"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "ellipsis doc-name" }
const _hoisted_3 = {
  key: 0,
  class: "docIsTeam ellipsis"
}
const _hoisted_4 = { class: "operate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!
  const _component_van_icon = _resolveComponent("van-icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listData, (item) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "list-css",
      key: item.id
    }, [
      _createElementVNode("div", {
        class: "list-detail",
        onClick: ($event: any) => (_ctx.handleDocClick(item))
      }, [
        _createElementVNode("div", null, [
          _createVNode(_component_van_image, {
            src: require(`@/assets/icon/${item.fileIcon}`),
            class: "img-type mr6"
          }, null, 8, ["src"])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(item.docName || item.folder), 1),
          (_ctx.fileType === 'home')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("span", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('doc.resource')) + "：", 1),
                  _createTextVNode(" " + _toDisplayString(item.fileSourceName), 1)
                ]),
                _createElementVNode("span", _hoisted_4, _toDisplayString(item.operateDate), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ], 8, _hoisted_1),
      _createVNode(_component_van_icon, {
        name: "ellipsis",
        onClick: ($event: any) => (_ctx.handleToOperate(item, 1))
      }, null, 8, ["onClick"])
    ]))
  }), 128))
}