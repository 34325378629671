export enum ESessionKey {
  TOKEN = "token", //账号密码调用getToken获取(接口返回的 data.accessToken )，或 从hengklink主站跳转过来 URL 中带token
  AUTHORIZATION = "Authorization", //一般为 `bearer ${token}`
  AUTHORITY = "authority", //调用checkToken 后 ，接口返回的`${data.authority}`
  CLIENT_ID = "clientId",
  HEADER_TABS = "headerTabs",
  USER_INFO = "userInfo",
  SEARCH_DATA = "searchData",
  USER_ID = "userId",
}
