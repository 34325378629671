
import { defineComponent, ref, watch, toRefs, getCurrentInstance } from 'vue';
import { Toast, Notify } from 'vant';
import { EDocType } from '@/serviceType/common';
import service from '@/service';
export default defineComponent({
    name: 'renameFile',
    props: {
        isShowDialog: {
            type: Boolean,
        },
        //提交参数
        submitObject: {
            type: Object,
        },
        //文件类型  团队和我的 要传 其他地方使用不用传
        docFileType: {
            type: Number,
        },
        isQuick: {
            type: Boolean,
        },
    },
    setup(props) {
        const { proxy }: any = getCurrentInstance();
        const { isShowDialog, submitObject, docFileType } = toRefs(props);
        const showDialog = ref(false);
        const parms: any = ref({});
        const userName: any = ref('');
        const temp = ref(false);
        const getShowPicker = () => {
            showDialog.value = isShowDialog.value;
            parms.value = submitObject.value;
            if (isShowDialog.value) {
                temp.value = false;
            }
            userName.value = submitObject.value?.docName || submitObject.value?.folderNewName || submitObject.value?.folderName;
        };

        watch(isShowDialog, getShowPicker);
        return {
            showDialog,
            parms,
            userName,
            temp,
            proxy
        };
    },
    methods: {
        submitTeam() {
            this.temp = true;
            if (this.userName != '') {
                if (this.docFileType === EDocType.Folder) {
                    if (this.parms.teamDocId) {
                        //团队  文件夹
                        this.parms.folderNewName = this.userName;
                        service.TeamService.renameTeamDir(this.parms).then((res: any) => {
                            if (res.code === 0) {
                                Notify({ type: 'success', message: this.proxy.$t('doc.renameSuccess') });
                                this.$emit('createFun', true);
                            } else {
                                Toast.fail(res.msg);
                                this.temp = false;
                                // this.$emit('createFun', false)
                            }
                        });
                    } else {
                        //我的  文件夹
                        this.parms.folderName = this.userName;
                        service.MyPageService.docMyRenameFolder({
                            currentUserId: this.parms.currentUserId,
                            docUserMyId: this.parms.docUserMyId,
                            folderName: this.parms.folderName,
                        }).then((res: any) => {
                            if (res.code === 0) {
                                Notify({ type: 'success', message: this.proxy.$t('doc.renameSuccess') });
                                this.$emit('createFun', true);
                            } else {
                                Toast.fail(res.msg);
                                this.temp = false;
                                // this.$emit('createFun', false)
                            }
                        });
                    }
                } else {
                    //其他文件
                    this.parms.docName = this.userName;
                    if (this.isQuick) {
                        service.HomeService.renameCreateQuick({
                            docKey: this.parms.docKey,
                            docName: this.parms.docName,
                        }).then((res: any) => {
                            if (res.code === 0) {
                                Notify({ type: 'success', message: this.proxy.$t('doc.quickAddRenameSuccess') });
                                this.$emit('createFun', true);
                            } else {
                                Toast.fail(res.msg);
                                this.temp = false;
                                // this.$emit('createFun', false)
                            }
                        });
                    } else {
                        service.CommonService.rename(this.parms).then((res: any) => {
                            if (res.code === 0) {
                                Notify({ type: 'success', message: this.proxy.$t('doc.renameSuccess') });
                                this.$emit('createFun', true);
                            } else {
                                Toast.fail(res.msg);
                                this.temp = false;
                                // this.$emit('createFun', false)
                            }
                        });
                    }
                }
            } else {
                this.temp = false;
            }

            // this.$emit('createFun', true)
        },
        cancel() {
            this.$emit('createFun', false);
        },
    },
});
