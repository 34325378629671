
import { defineComponent, ref, watch, toRefs } from 'vue'

export default defineComponent({
  name: 'dialogCreate',
  props: {
    isShowDialog: {
      type: Boolean,
    },
    columns: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  setup(props) {
    const { isShowDialog } = toRefs(props)
    const showDialog = ref(false)
    const getShowPicker = () => {
      showDialog.value = isShowDialog.value
    }
    watch(isShowDialog, getShowPicker)

    // let showDialog = ref(false)
    // watch(
    //   () => props.isShowDialog,
    //   () => {
    //     showDialog.value = props.isShowDialog
    //   }
    // )
    return {
      showDialog,
    }
  },
})
