import request from "../utils/request";
import qs from "qs";
import { BASE_URL } from "./config/index";
import { EHttpMethod } from "@henglink/plugins/dist/src/logan/types";
import { EApolloConfigKey } from "@/serviceType/common";
const { version } = require("../../package.json");

class CommonService {
  // 创建副本
  createCopy(params: any) {
    return request({
      url: `${BASE_URL.COMMON}/createCopy`,
      method: "post",
      data: params,
    });
  }
  // 创建文件夹
  createDir(params: any) {
    return request({
      url: `${BASE_URL.COMMON}/createDir`,
      method: "post",
      data: params,
    });
  }
  // 移动个人或团队文件
  docMove(params: any) {
    return request({
      url: `${BASE_URL.COMMON}/docMove`,
      method: "post",
      data: params,
    });
  }
  // 删除文档
  delete(params: any) {
    return request({
      url: `${BASE_URL.COMMON}/delete`,
      method: "post",
      data: params,
    });
  }
  // 获取下载地址
  getDownLoadUrl(params: any) {
    const paramsStr = qs.stringify(params);
    return request({
      url: `${BASE_URL.COMMON}/getDownLoadUrl?${paramsStr}`,
      method: "get",
      data: params,
    });
  }
  //   获取PDF下载地址
  getDownLoadPDFUrl(params: any) {
    const paramsStr = qs.stringify(params);
    return request({
      url: `${BASE_URL.COMMON}/getDownLoadPDFUrl?${paramsStr}`,
      method: "get",
      data: params,
    });
  }
  // 发送、分享
  docCommonImSend(params: any, docKey: string) {
    return request({
      url: `${BASE_URL.COMMON}/imSend/${docKey}`,
      method: "post",
      data: params,
    });
  }
  // 新建文档
  newDocument(params: any) {
    return request({
      url: `${BASE_URL.COMMON}/newDocument`,
      method: "post",
      data: params,
    });
  }
  // 移除记录（最近打开和编辑）
  removeRecord(params: any) {
    return request({
      url: `${BASE_URL.COMMON}/removeRecordByUserId`,
      method: "post",
      data: params,
    });
  }
  // 重命名
  rename(params: any) {
    return request({
      url: `${BASE_URL.COMMON}/rename`,
      method: "post",
      data: params,
    });
  }
  // 上传文件
  uploadDocument(params: any) {
    return request({
      url: `${BASE_URL.COMMON}/uploadDocument`,
      method: "post",
      data: params,
    });
  }

  // 添加快速新建
  addQuickNew(params: any) {
    return request({
      url: `${BASE_URL.CREATEQUICK}/addQuickNew`,
      method: "post",
      data: params,
    });
  }
  // 文件搜索
  searchFile(params: any) {
    return request({
      url: `${BASE_URL.COMMON}/searchFile`,
      method: "post",
      data: params,
    });
  }
  // 获取阿波罗配置
  getConfigs(configKeyList: Array<EApolloConfigKey>) {
    return request({
      url: `${BASE_URL.COMPONENT}/apollo/getValuesByKeyList`,
      method: EHttpMethod.POST,
      data: {
        configKeyList,
        apolloTypeEnum: "JSON",
      },
    });
  }

  async fileDownload(fileUrl: string) {
      const { data } = await request({
        data: { fileUrl },
        method: EHttpMethod.POST,
        url: `${BASE_URL.CLOUD_DISK}/index/fileDownload`,
      });
      if (data) {
        return data;
      }
      return "";
  }
}

export default new CommonService();
