
import { defineComponent, getCurrentInstance } from 'vue';
import { ERoutName } from '../router/type'
import { useRoute, useRouter } from 'vue-router';
export default defineComponent({
    name: 'TopBarNav',
    props: {
        navTitle: {
            type: String,
            default: () => {
                return getCurrentInstance()?.appContext.config.globalProperties.$t('doc.hengliDoc')
            },
        },
        callback: {
            type: Function,
        },
        ishow: {
            type: Boolean
        }
    },
    setup() {
        const router = useRouter();
        return {
            router
        };
    },
    methods: {
        onClickLeft() {
            if (this.callback) {
                this.callback();
            } else {

                this.router.back();
            }
        },
        search() {
            this.router.push({
                path: '/' + ERoutName.searchList,
            })
        }
    },
});
